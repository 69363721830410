.admin-page__list-comand-button {
    border: 0;
    padding-top: 10px;
    margin: 0;
    font-size: 16px;
    text-align: left;
    padding-left: 0;
    font-weight: bold;
    width: 39px;
    background-color: transparent !important;
}
.formadd__input-text {
    font-size: 14px;
    color: black;
    padding: 0 15px 0 0;
    margin: 0;
    position: absolute;
    right: 23px
}

@media (max-width: 900px) {
    .formadd__input-text {
        right: 39px
    }
}